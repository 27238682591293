import React, { Component, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { appTheme } from 'common/src/theme/app';
import { GlobalStyle, AppWrapper } from '../../containers/App/app.style';
import { ResetCSS } from '../../../../common/src/assets/css/style';
import Navbar from '../../containers/App/Navbar';
import Footer from '../../containers/App/Footer';
import { DrawerProvider } from '../../../../common/src/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import SEO from '../../components/seo';
import DomainSection from '../../containers/App/Banner';

class Home extends Component {
  render() {
    const context = this.props.pageContext;

    const title = {
      content: context.headline,
      fontSize: ['26px', '30px', '30px', '48px', '60px'],
      fontWeight: '300',
      color: '#0f2137',
      letterSpacing: '-0.01px',
      mb: '20px',
    };
    const description = {
      content: context.content.json,
    };

    return (
      <ThemeProvider theme={appTheme}>
        <Fragment>
          <SEO title={context.slug} />
          <ResetCSS />
          <GlobalStyle />
          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar
                  project={context.project}
                  menuItems={context.menuItems}
                />
              </DrawerProvider>
            </Sticky>
            <DomainSection
              title={title}
              description={description}
              project={context.project}
            />
            <Footer
              project={context.project}
              copyright={context.copyright}
              menuItems={context.menuItems}
            />
          </AppWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Home;
